/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import 'expose-loader?$!jquery'
import 'expose-loader?jQuery!jquery'
import 'bootstrap/dist/js/bootstrap'
import 'bootstrap-select/dist/js/bootstrap-select'
import 'sticky-table-headers/js/jquery.stickytableheaders'
import 'bootstrap-datepicker/dist/js/bootstrap-datepicker'
import 'bootstrap-datepicker/dist/locales/bootstrap-datepicker.sv.min'
import 'trix'
import '@fortawesome/fontawesome-free'

import Rails from '@rails/ujs'
Rails.start()

import Turbolinks from 'turbolinks'
Turbolinks.start()

// eslint-disable-next-line no-undef
require.context('../images', true)

import * as Sentry from '@sentry/browser'
if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    environment: process.env.SENTRY_CURRENT_ENV,
  })
}
