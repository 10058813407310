var ImportStatusPoller = {
  poll: function () {
    setTimeout(ImportStatusPoller.request, 1000)
  },

  request: function () {
    $.get({
      url: $('#import-status').data('url'),
      dataType: 'script',
    })
  },
}

document.addEventListener('turbolinks:load', () => {
  if ($('#import-status').length > 0) {
    ImportStatusPoller.poll()
  }
})

document.addEventListener('import-status-poll', () => {
  ImportStatusPoller.poll()
})
