const setupArticleLinks = () => {
  $('[data-articles-modal-query]').click((e) => {
    let link = $(e.target)
    openArticlesModal(link.data('articles-modal-query'), link.data('new-modal'))
  })
  $('[data-select-articles]').click(selectArticlesClick)
  $('#new_card .card-form')
    .find('.trix-content')
    .first()
    .on('input', function () {
      enableDisableCreateCardButton(
        $(this).text().length,
        $('#new_card input[type = checkbox].article-checkbox:checked').length,
      )
    })
}

document.addEventListener('turbolinks:load', setupArticleLinks)
document.addEventListener('ajax:success', setupArticleLinks)

const selectArticlesClick = () => {
  $('#article-container').empty()
  if (
    $('form#new_card input[type=checkbox].article-checkbox:checked').length > 0
  ) {
    $('#no-article-container').hide()
    $.each(
      $('form#new_card input[type=checkbox].article-checkbox:checked'),
      function () {
        var span =
          '<span class=\'competence-container article\' style=\'display:inline-block\'>' +
          $(this).attr('id') +
          '</span>'
        $('#article-container').append(span)
      },
    )
  } else {
    $('#no-article-container').show()
  }
}

const openArticlesModal = (modal_id, isNewModal) => {
  $(modal_id).modal('show')
  enableDisableArticlesSaveButton(modal_id, !!isNewModal)
  $(modal_id + ' input[type = checkbox].article-checkbox').each(function () {
    $(this).off('change')
    $(this).on('change', () => {
      enableDisableArticlesSaveButton(modal_id, !!isNewModal)
    })
  })
}

const enableDisableArticlesSaveButton = (modal_id, isNewModal) => {
  var modal_checkboxes =
    modal_id + ' input[type = checkbox].article-checkbox:checked'
  if ($(modal_checkboxes).length > 0) {
    $(modal_id + ' .articles-save-button').removeClass('disabled-item')
  } else {
    $(modal_id + ' .articles-save-button').addClass('disabled-item')
  }
  if (isNewModal) {
    // for create card check if we have added description + articles
    enableDisableCreateCardButton(
      $('#new_card .card-form').find('.trix-content').first().text().length,
      $(modal_checkboxes).length,
    )
  }
}

const enableDisableCreateCardButton = (inputLength, checkedLength) => {
  if (inputLength > 0 && checkedLength > 0) {
    $('#create-card-button').removeClass('disabled-item')
  } else {
    $('#create-card-button').addClass('disabled-item')
  }
}
