document.addEventListener('turbolinks:load', () => {
  $('input[data-search-for].table-search').keyup((event) => {
    var table = $($(event.target).data('search-for'))
    var searchSplit = event.target.value.replace(/ /g, '\'):containsi(\'')

    $.extend($.expr[':'], {'containsi': function(elem, i, match){
      return (elem.textContent || elem.innerText || '').toLowerCase().indexOf((match[3] || '').toLowerCase()) >= 0
    }
    })

    table.find('tbody tr').not(':containsi(\'' + searchSplit + '\')').each(function(){
      $(this).addClass('d-none')
    })

    table.find('tbody tr:containsi(\'' + searchSplit + '\')').each(function(){
      $(this).removeClass('d-none')
      $(this).addClass('bg-success kk-bg-lighter')
    })

    if (event.target.value.length == 0) {
      table.find('tbody tr').removeClass('bg-success kk-bg-lighter')
    }
  })

  $('input[data-search-for].card-search').keyup((event) => {
    var cards = $($(event.target).data('search-for'))
    var searchSplit = event.target.value.replace(/ /g, '\'):containsi(\'')

    $.extend($.expr[':'], {'containsi': function(elem, i, match ){
      return (elem.textContent || elem.innerText || '').toLowerCase().indexOf((match[3] || '').toLowerCase()) >= 0
    }
    })

    cards.find('.card').not(':containsi(\'' + searchSplit + '\')').each(function(){
      $(this).addClass('d-none')
    })

    cards.find('.card:containsi(\'' + searchSplit + '\')').each(function(){
      $(this).removeClass('d-none')
    })
  })
})
