$(document).on('turbolinks:before-visit', (e) => {
  if (hasDirtyForm()) {
    if (!showUnsavedConfirm()) {
      e.preventDefault()
    }
  }
})

$(document).on('hide.bs.modal', (event) => {
  var modal_form = event.target.querySelector('form[data-check-dirty-form]')
  if (modal_form) {
    if (formIsDirty([modal_form])) {
      return showUnsavedConfirm()
    }
  }
})

$(document).on('trix-initialize', (event) => {
  // handle trix editor if in a check dirty form
  if ($(event.target).parents('form[data-check-dirty-form]').length) {
    var hidden_trix_input = $(event.target.parentNode).find(
      '#' + event.target.getAttribute('input'),
    )
    if (hidden_trix_input) {
      // set data to check if it changed in formIsDirty
      hidden_trix_input.attr('data-initial-value', hidden_trix_input.val())
    }
  }
})

const showUnsavedConfirm = () => {
  return confirm(
    'Du har osparade ändringar. Är du säker på att du vill fortsätta? OK = inga ändringar sparas.',
  )
}

const hasDirtyForm = () => {
  return formIsDirty(document.querySelectorAll('form[data-check-dirty-form]'))
}

$(document).on('turbolinks:load', () => {
  $('form').submit(() => {
    // dont show unsaved dialog if we are submiting
    $('form').attr('data-is-submiting', true)
  })
})

const formIsDirty = (forms) => {
  // if we pressed a submit do not check dirty form

  if (document.activeElement.type === 'submit') {
    return false
  }
  for (var i = 0; i < forms.length; i++) {
    var form = forms[i]
    if (!form.dataset.isSubmiting && $(form).is(':visible')) {
      // check if form is in modal, and if its not open, no need to check if its dirty
      // can happen eg if we set window.location after closing modal
      var form_modal = $(form).closest('.modal').data('bs.modal')
      if (form_modal && !form_modal._isShown) {
        continue
      }
      for (var j = 0; j < form.elements.length; j++) {
        var element = form.elements[j]
        var type = element.type
        if (type == 'checkbox' || type == 'radio') {
          if (element.checked != element.defaultChecked) {
            return true
          }
        } else if (type == 'password' || type == 'text' || type == 'textarea') {
          if (element.value != element.defaultValue) {
            return true
          }
        } else if (type == 'hidden') {
          // hidden input needs to have data-initial-value
          if (
            element.dataset.initialValue !== undefined &&
            element.value != element.dataset.initialValue
          ) {
            return true
          }
        } else if (type == 'select-one' || type == 'select-multiple') {
          for (var k = 0; k < element.options.length; k++) {
            var option = element.options[k]
            if (
              option.selected != option.defaultSelected &&
              option.value &&
              option.value.length > 0
            ) {
              return true
            }
          }
        }
      }
    }
  }
  return false
}
