document.addEventListener('turbolinks:load', () => {
  var authorization = $('#authorization').get(0)

  if (!authorization) { return }

  var mapping = {
    'disable-edit': '.edit-link',
    'disable-destroy': '.destroy-link'
  }

  Object.keys(mapping).forEach((key) => {
    if ($(authorization).data(key)) {
      var ids = $(authorization).data(key).split(',')
      ids.forEach((id) => {
        var item = $('#' + id + ' ' + mapping[key])
        item.hide()
        if (item.parents('.dropdown-menu:first').children(':visible').length == 0) {
          $('#' + item.parents('.dropdown-menu:first').attr('aria-labelledby')).hide()
        }
      })
    }
  })
})
