document.addEventListener('turbolinks:load', () => {
  $('input[data-search-url]#user-search').keyup((event) => {
    if (event.target.value.length == 0) {
      $('#user-search-results tbody').empty()
      $('#user-search-results-container').hide()
    } else {
      $.post($(event.target).data('search-url'), { query: event.target.value })
    }
  })
})
