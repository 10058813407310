document.addEventListener('turbolinks:load', () => {
  loadAnalysisClickListeners()
})

document.addEventListener('management-analysis-update', () => {
  loadAnalysisClickListeners()
})

const loadAnalysisClickListeners = () => {
  $('[data-toggle="tooltip"]').tooltip()
  $('.history').off('click')
  $('.history').on('click', function () {
    $(this).text((i, old) => {
      return old === 'Visa historik' ? 'Dölj historik' : 'Visa historik'
    })
  })

  $('.fullscreen').off('click')
  $('.fullscreen').on('click', function () {
    $(this).closest('.card').toggleClass('fs-active')
    $(this).text((i, old) => {
      return old === 'Visa i fullskärm'
        ? 'Avsluta fullskärmsäge'
        : 'Visa i fullskärm'
    })
    $('#sidenav').toggleClass('hide')
  })

  $('.exit-edit').off('click')
  $('.exit-edit').on('click', function () {
    $(this).siblings('.fullscreen').text('Visa i fullskärm')
    $(this).closest('.card').removeClass('fs-active')
    $('#sidenav').removeClass('hide')
  })
}
