import './application'
import './shared'

import { ProgressBar } from 'turbolinks'
import Rails from '@rails/ujs'

import bsCustomFileInput from 'bs-custom-file-input'
import bootoast from 'bootoast/dist/bootoast.min'

// make it global
window.bootoast = bootoast

import '../magenta/authorization'
import '../magenta/management_analyses'
import '../magenta/prognoses'
import '../magenta/search'
import '../magenta/user-search'
import '../magenta/admin/import_students'
import '../magenta/articles'

document.addEventListener('turbolinks:before-cache', () => {
  $('.selectpicker').selectpicker('destroy').addClass('selectpicker')
})

window.addEventListener('turbolinks:before-render', () => {
  window.zEACLoaded = undefined
})

document.addEventListener('turbolinks:load', () => {
  $('.selectpicker').selectpicker({
    noneSelectedText: 'Inget valt',
  })

  bsCustomFileInput.init()

  $('.dropdown-menu a.dropdown-item').click(() => {
    $('.dropdown-menu.show').removeClass('show')
  })

  $('[data-toggle="tooltip"]').tooltip()
  $('[data-toggle="popover"]').popover()

  listenForFormSubmit()

  $('#table-sticky-header').stickyTableHeaders({
    fixedOffset: $('#top-header'),
  })

  // SCROLLING TO ANCHOR LINKS WITH OFFSET FROM EXTERNAL PAGES
  var urlHash = window.location.href.split('#')[1]
  if (urlHash && $('#' + urlHash).length) {
    $('html,body').animate(
      {
        scrollTop: $('#' + urlHash).offset().top - 150,
      },
      100,
    )
  }
})

const listenForFormSubmit = () => {
  $('.submittable')
    .off('change')
    .on('change', function () {
      Rails.fire($(this).parents('form:first')[0], 'submit')
    })
}

var ajaxProgressBar = new ProgressBar()

document.addEventListener('ajax:send', (e) => {
  ajaxProgressBar.setValue(0)
  ajaxProgressBar.show()

  // Don't rely on the 'ajax:complete' event to hide the progress bar,
  // since this is not triggered if the form triggering the request
  // is replaced. Instead, add a listener directly to the request.
  e.detail[0].addEventListener('load', () => {
    ajaxProgressBar.setValue(1)
    ajaxProgressBar.hide()
    // make sure tooltip popover is loaded
    $('[data-toggle="tooltip"]').tooltip()
    $('[data-toggle="popover"]').popover()
    $('form[data-remote] button[data-form-cancel]').click((e) => {
      e.preventDefault()
      var target = $(e.target)
      // check if this is a new model, in that case just remove everything
      var new_model = target
        .closest('.model-form')
        .parent()
        .closest('#new_' + target.data('form-cancel'))
      if (new_model.length > 0) {
        new_model.remove()
      } else {
        var container = target.closest('.model-form').parent()
        container.find('.model-view').show()
        container.find('.model-form').hide()
      }
    })
    listenForFormSubmit()
  })
})

document.addEventListener('ajax:success', () => {
  $('.dropdown-menu a.dropdown-item').click(() => {
    $('.dropdown-menu.show').removeClass('show')
  })
})

document.addEventListener('ajax:error', () => {
  bootoast.toast({
    type: 'danger',
    position: 'top',
    dismissible: false,
    message: 'Det gick inte att kontakta servern! Försök igen senare.',
  })
})

$(document).on('click', 'a[href^="#"]', (event) => {
  // hmm should href=# be used ?
  event.preventDefault()
})

$(document).on('click', '#sidenav a[href^="#"]', function (event) {
  event.preventDefault()
  $('html, body').animate(
    {
      scrollTop: $($.attr(this, 'href')).offset().top + -150,
    },
    1000,
  )
})
