$(() => {
})

var options = {
  enableInteractivity: false,
  legend: { position: 'none' },
  pieSliceBorderColor: 'transparent',
  backgroundColor: 'transparent',
  width: 30,
  height: 30,
  colors: ['#A22600', '#FFD317', '#B0DB52'],
  pieSliceText: 'none'
}

document.addEventListener('turbolinks:load', () => {
  google.charts.load('current', {'packages':['corechart']})
  google.charts.setOnLoadCallback(() => {
    $('.prognoses-piechart').each(function() {
      var data = google.visualization.arrayToDataTable([
        ['prognosis', 'distribution'],
        ['red', $(this).data('red')],
        ['yellow', $(this).data('yellow')],
        ['green', $(this).data('green')]
      ])
      const chart = new google.visualization.PieChart(this)
      chart.draw(data, options)
    })
  })
})
